class FbEvents {
  constructor() {
    this.firedFbEvents = [];
  }

  fireEvent(event, options) {
    const { config } = window._app;
    if (!window.fbq) return;
    if (!config || !config.facebookTracking) return;
    if (this.firedFbEvents.includes(event)) return;

    this.firedFbEvents.push(event);
    console.log("FB Invoke Event", event);
    window.fbq("track", event, options);
  }
  viewContent() {
    this.fireEvent("ViewContent");
  }
  initiateCheckout() {
    this.fireEvent("AddToCart");
    this.fireEvent("InitiateCheckout");
  }
  addPaymentInfo() {
    this.fireEvent("AddPaymentInfo");
  }
  finishPurchase() {
    const { config } = window._app;

    if (!config.plan) {
      this.fireEvent("Purchase", { value: config.price, currency: "USD" });
      return;
    }

    const {
      plan: {
        currencyIsoCode: currency,
        numberOfBillingCycles,
        trialPeriod,
        price,
      },
    } = config;

    const predicted_ltv = numberOfBillingCycles * price;

    if (trialPeriod) {
      this.fireEvent("StartTrial", { value: "0.00", currency, predicted_ltv });
    } else {
      this.fireEvent("Subscribe", { value: price, currency, predicted_ltv });
    }
  }
}

module.exports = FbEvents;
