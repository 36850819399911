class SubmissionError extends Error {
  constructor(errors) {
    super("SubmissionError");
    this.name = "SubmissionError";
    this.errors = errors;
  }
}

class Form {
  constructor(selector) {
    const self = this;

    const $form = $(selector);
    this.$form = $form;

    $form.on("submit", async function (e) {
      e.preventDefault();
      self.resetErrors();
      const values = self.getValues();

      try {
        await self.submit(values);
      } catch (err) {
        if (err instanceof SubmissionError) {
          self.setErrors(err.errors);
        }
      }
    });

    $form.on("keydown", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });

    $form.on("input change", function (e) {
      self.resetErrors(e.target.name);
    });

    // Add error fields
    $form.find("[name]").parent().append('<span class="error">');
  }

  getValues() {
    const array = this.$form.serializeArray();
    const values = {};

    array.forEach((prop) => {
      values[prop.name] = prop.value;
    });

    return values;
  }

  setErrors(errors) {
    this.$form.addClass("error-state");
    Object.keys(errors).map((key) => {
      $(`[name="${key}"]`)
        .parent()
        .find(".error")
        .addClass("visible")
        .text(errors[key]);
    });
  }

  resetErrors(name) {
    if (name) {
      this.$form
        .find(`[name='${name}']`)
        .parent()
        .find(".error")
        .text("")
        .removeClass("visible");
    } else {
      this.$form.find(".error").text("").removeClass("visible");
    }
  }

  resetForm() {
    this.$form.trigger("reset");
  }
}

module.exports = Form;
module.exports.SubmissionError = SubmissionError;
