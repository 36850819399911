$(function () {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require("./back-to-top.js");
  require("./target-blank.js");
  require("./translate.js");
  require("./video.js");
  require("./app.js");
});
