const Form = require("./form");

class UserValuesForm extends Form {
  constructor(selector) {
    super(selector);
    const self = this;

    this.$form.on("click", ".nextBtn", nextStepHandler);

    async function nextStepHandler(e) {
      e.preventDefault();
      const $currentStep = $(this).closest("[data-order-form-step]");
      const $nextStep = $currentStep.next("[data-order-form-step]");

      const valid = self.$form[0].reportValidity();
      if (!valid) return;

      self.resetErrors();

      const values = self.getValues();

      // Check values
      try {
        const success = await window._app.service.checkUserValues(values);

        if (!success) return;

        if (window._app.config.paymentServiceName === "braintree") {
          window._app.openCheckout(values);
          // Go to next step
          // $currentStep.fadeOut(0);
          // $nextStep.fadeIn();

          // $("#agreement").attr("disabled", false);

          return;
        }

        self.submit(values);
      } catch (errors) {
        if (errors) self.setErrors(errors);
      }
    }

    this.$form.on("click", ".prevBtn", prevStepHandler);

    function prevStepHandler(e) {
      e.preventDefault();

      const $currentStep = $(this).closest("[data-order-form-step]");
      const $prevStep = $currentStep.prev("[data-order-form-step]");

      if (!$prevStep.length) return;

      $("#agreement").attr("disabled", true);
      $currentStep.fadeOut(0);
      $prevStep.fadeIn();
    }
  }

  addLevelSubscriptionFields() {
    const { levelSubscriptionPlans } = window._app.config;

    $("#plan-id-row").show();

    $('[name="plan_id"]').append(function () {
      return levelSubscriptionPlans.map((p) =>
        $(`<option value="${p.id}">${p.label}</option>`)
      );
    });
  }

  removeLevelSubscriptionFields() {
    $("#plan-id-row").remove();
  }

  async submit(values) {
    // Check values
    const [errors, success] = await window._app.service.makeOrder(values);

    if (errors) {
      throw new Form.SubmissionError(errors);
    }

    if (success) {
      this.resetForm();
      this.$form.hide();
      window._app.showThankYou();
    }
  }
}

module.exports = UserValuesForm;
