(function () {
  var myVideo = $("#promo-video")[0];
  $("#play-video").on("click", function () {
    myVideo.play();
  });

  $("#promo-video").on("click", function () {
    if (!myVideo.paused) {
      myVideo.pause();
    } else {
      myVideo.play();
    }
  });

  $("[data-video-src]").on("click", function () {
    if ($(this).hasClass("active")) return;

    $("[data-video-src].active").removeClass("active");
    $(this).addClass("active");
    const src = $(this).data("video-src");

    const $activityVideo = $("#activity-video");
    const height = $activityVideo.height();

    $activityVideo.one("loadeddata", () => {
      $activityVideo.height("auto");
      $activityVideo[0].play();
    });
    $activityVideo.height(height).attr("src", src);
  });

  $(".promo__video-poster").on("click", function () {
    $(this).fadeOut();
    $(".promo__video").css({ opacity: 1 })[0].play();
  });
})();
