(function () {
  const languageSelect = $("#language-select");
  if (!languageSelect.length) return;

  $.cookie.raw = true;

  const labels = {
    en: "English",
    es: "Spanish",
    sw: "Kiswahili",
  };
  const originLocale = "en";
  const googleTranslateConfig = { lang: originLocale };
  const localeFromCookie = getLocaleFromCookie();

  document.dispatchEvent(
    new CustomEvent("set-locale", {
      detail: {
        locale: {
          code: localeFromCookie || originLocale,
          label: labels[localeFromCookie || originLocale],
        },
      },
    })
  );

  let ready = false;
  let bindedTranslateInit;

  function translateInit(locales, defaultLocale = "en") {
    if (!ready) {
      bindedTranslateInit = translateInit.bind(this, locales, defaultLocale);
      return;
    }

    if (
      localeFromCookie == undefined &&
      defaultLocale &&
      defaultLocale !== originLocale
    ) {
      translateSetCookie(defaultLocale);
      window.location.reload();
    }

    if (locales && locales.length > 1) {
      locales.forEach((code) => {
        languageSelect.append(
          `<option value="${code}">${labels[code]}</option>`
        );
      });

      const code =
        localeFromCookie ||
        (localeFromCookie === "" ? originLocale : defaultLocale);

      languageSelect.val(code || originLocale).show();
    }

    if (localeFromCookie === googleTranslateConfig.lang) {
      translateClearCookie();
    }

    new google.translate.TranslateElement({
      pageLanguage: googleTranslateConfig.lang,
      includedLanguages: locales ? locales.join(",") : "",
    });

    languageSelect.on("change", function (e) {
      translateSetCookie(e.target.value);
      window.location.reload();
    });
  }

  window.translateElementReady = function () {
    ready = true;
    bindedTranslateInit && bindedTranslateInit();
  };

  window.translateInit = translateInit;

  function getLocaleFromCookie() {
    const cookieValue = $.cookie().googtrans;

    if (!cookieValue) return cookieValue;
    if (cookieValue == "null") return "";

    return cookieValue.substr(-2);
  }

  const domainParts = document.domain.split(".");

  let domain = document.domain;

  if (domainParts.length === 3) {
    // without subdomain
    domain = `${domainParts[1]}.${domainParts[2]}`;
  }

  function translateClearCookie() {
    setGoogleTransToCookie(null);
  }

  function setGoogleTransToCookie(value) {
    $.cookie("googtrans", value);
    $.cookie("googtrans", value, { path: "/" });
    $.cookie("googtrans", value, {
      domain,
      path: "/",
    });
    $.cookie("googtrans", value, {
      domain: "." + domain,
      path: "/",
    });
  }

  function translateSetCookie(code) {
    setGoogleTransToCookie("/auto/" + code);
  }
})();
