(function () {
  $(window).on("scroll", function () {
    const height = $(window).scrollTop();
    if (height > 100) {
      $(".back-to-top").stop().fadeIn();
    } else {
      $(".back-to-top").stop().fadeOut();
    }
  });

  $("[data-scroll-to]").on("click", function (e) {
    e.preventDefault();
    const anchor = $(this).data("scroll-to");
    const anchorTop = $(anchor).offset().top - 50;
    $("html, body").animate({ scrollTop: anchorTop }, "slow");
  });
})();
