const apiUrls = {
  local: "http://localhost:3000/api",
  test: "https://2asc6ikxgg.execute-api.us-east-2.amazonaws.com/test/api",
  prod: "https://h2qbohm2i8.execute-api.us-east-2.amazonaws.com/prod/api",
};

class Service {
  constructor(isTest, isLocal) {
    $.ajaxSetup({
      headers: { "x-full-referer-path": location.href },
    });

    if (isLocal) {
      this.apiUrl = apiUrls.test;
    } else {
      this.apiUrl = isTest ? apiUrls.test : apiUrls.prod;
    }
  }

  showProcessingModal() {
    window._app.showModal("Processing. Please wait...", {
      modal: true,
      className: "processing-popup",
    });
  }

  hideProcessingModal() {
    $.magnificPopup.close();
  }

  apiCall(opts) {
    const self = this;

    return new Promise((resolve, reject) => {
      $.ajax(opts)
        .done((response) => {
          self.hideProcessingModal();
          resolve(response.data || true);
        })
        .fail((error) => self.handleResponseError(error, reject));
    });
  }

  checkUserValues(data) {
    this.showProcessingModal();
    return this.apiCall({
      data,
      url: this.apiUrl + "/purchases/check-values",
      method: "POST",
    });
  }

  fetchConfig() {
    return this.apiCall(this.apiUrl + "/purchases/page-config");
  }

  fetchClientToken() {
    return this.apiCall(this.apiUrl + "/purchases/client-token");
  }

  handleResponseError(error, reject) {
    this.hideProcessingModal();

    if (error && error.responseJSON) {
      if (error.responseJSON.transactionError && window._app.dropinInstance) {
        window._app.dropinInstance.clearSelectedPaymentMethod();
      }

      error.responseJSON.error &&
        window._app.showModal(error.responseJSON.error);
    }

    const errors = error && error.responseJSON && error.responseJSON.errors;

    reject && reject(errors);
  }

  makeOrder(data) {
    const { paymentServiceName } = window._app.config;

    if (paymentServiceName === "shopify") {
      return this.redirectToShopifyPage(data);
    }

    if (paymentServiceName === "free") {
      return this.createFamilyByCode(data);
    }
  }

  async makeBraintreePayment(data) {
    const self = this;
    const { fbEvents, dropinInstance } = window._app;

    this.showProcessingModal();

    try {
      const payload = await dropinInstance.requestPaymentMethod();
      fbEvents.addPaymentInfo();
      return makePayment(payload);
    } catch (err) {
      return [null, null];
    }

    function makePayment(payload) {
      return new Promise((resolve, reject) => {
        $.ajax({
          data: JSON.stringify({
            ...data,
            payment_method_nonce: payload.nonce,
            device_data: payload.deviceData,
          }),
          contentType: "application/json; charset=utf-8",
          url: self.apiUrl + "/purchases/braintree-payment",
          method: "POST",
        })
          .then((response) => {
            self.hideProcessingModal();
            resolve(response);
          })
          .fail((error) => {
            self.handleResponseError(error, reject);
          });
      });
    }
  }

  createFamilyByCode(data) {
    const self = this;

    self.showProcessingModal();

    return $.ajax({
      data,
      url: self.apiUrl + "/purchases/create-by-code",
      method: "POST",
    })
      .then((response) => {
        if (response && response.success) {
          const redirectTo = response.data.redirectTo;
          location.href = redirectTo;
        }

        self.hideProcessingModal();
        return [null, false];
      })
      .fail((error) => {
        self.handleResponseError(error);
      });
  }

  redirectToShopifyPage(data) {
    const self = this;

    self.showProcessingModal();

    return $.ajax({
      data,
      url: self.apiUrl + "/purchases/get-shopify-url",
      method: "POST",
    })
      .then((response) => {
        self.hideProcessingModal();

        if (response && response.success) {
          const redirectTo = response.data.redirectTo;
          window.open(redirectTo);
          return [null, true];
        }

        return [null, false];
      })
      .fail((error) => {
        self.handleResponseError(error);
      });
  }
}

module.exports = Service;
