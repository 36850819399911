"use strict";

const Form = require("./form");

class CheckoutForm extends Form {
  constructor(selector) {
    super(selector);
  }

  async submit(values) {
    const { fbEvents, service } = window._app;
    const withShareASale = !!document.getElementById("share-a-sale-script");

    try {
      const { success, data } = await service.makeBraintreePayment({
        ...values,
        withShareASale,
      });

      if (!success) return;

      const { redirectTo, thankYouHtml } = data;

      window._app.showModal(thankYouHtml, {
        modal: true,
        closeButton: true,
        callbacks: {
          close: () => (location.href = redirectTo),
        },
      });

      fbEvents.finishPurchase();

      // setTimeout(() => {
      //   debugger;
      //   location.href = redirectTo;
      // }, 2000);
    } catch (errors) {
      throw new Form.SubmissionError(errors);
    }
  }
}

module.exports = CheckoutForm;
