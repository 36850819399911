const FbEvents = require("./fb-events");
const Service = require("./service");
const UserValuesForm = require("./user-values-form");
const CheckoutForm = require("./checkout-form");

const checkoutPageName = "checkout.html";
class App {
  constructor() {
    window._app = this;
    this.isTest = location.search.indexOf("mode=test") !== -1;
    this.isLocal = process.env.NODE_ENV !== "production";

    if (this.isTest || this.isLocal) {
      $("body").addClass("test");
    }

    this.isCheckoutPage = location.pathname.indexOf(checkoutPageName) !== -1;
    this.service = new Service(this.isTest, this.isLocal);
    this.fbEvents = new FbEvents();
    this.userValuesForm = new UserValuesForm("#order-form");

    if (process.env.NODE_ENV !== "production") {
      populateFormWithTestValues();
    }

    $(document).on('click', '#close-popup', function (e) {
      e.preventDefault();
      $.magnificPopup.close();
    });

    this.fetchConfig();
  }

  showFormSpinner() {
    $("#form-spinner").fadeIn();
  }

  hideFormSpinner() {
    $("#form-spinner").fadeOut();
  }

  async fetchConfig() {
    this.showFormSpinner();
    const config = await this.service.fetchConfig();
    this.hideFormSpinner();

    if (!config) return;

    this.config = config;
    this.appendConfig();
  }

  appendConfig() {
    this.appendFooterLinks();

    if (this.isCheckoutPage) {
      this.fbEvents.initiateCheckout();
      this.checkoutForm = new CheckoutForm(".checkout-page__form");
    } else {
      this.fbEvents.viewContent();
    }

    const {
      price,
      assets,
      codeAccess,
      isLevelSubscription,
      paymentServiceName,
      locales,
      defaultLocale,
      loginUrl,
    } = this.config;

    $("[data-price]").text("$" + price);

    // Set HERO
    if (assets && assets.hero) {
      $(".hero__image").attr("src", assets.hero);
      $(".promo").css("background-image", `url(${assets.hero})`);
    }

    if (assets) {
      applyAssets(assets);
    }

    function applyAssets(assets) {
      if (assets.hero) {
        $(".hero__image").attr("src", assets.hero);
        $(".promo").css("background-image", `url(${assets.hero})`);
      }

      if (assets.videoPoster) {
        $(".promo__video-poster").attr("src", assets.videoPoster).fadeIn();
      }
    }

    $(".hero__image").show();

    window.translateInit(locales, defaultLocale);

    const appendLoginUrl = () => {
      $("#login").show().find("a").attr("href", loginUrl);
    };

    if (loginUrl) {
      appendLoginUrl();
    }

    if (this.isCheckoutPage && paymentServiceName === "braintree") {
      this.createDropIn();
    }

    if (codeAccess) {
      $("#code-row").show();
    } else {
      $("#code-row").remove();
    }

    if (isLevelSubscription) {
      this.userValuesForm.addLevelSubscriptionFields();
    } else {
      this.userValuesForm.removeLevelSubscriptionFields();
    }
  }

  appendFooterLinks() {
    const { links = [] } = this.config;

    links.forEach((link) => {
      if (!link || !link.text || !link.url) return;

      $("#footer-links").append(
        `<li><a target="_blank" href="${link.url}">${link.text}</a></li>`
      );
    });
  }

  async createDropIn() {
    const createDropInHandler = (createErr, instance) => {
      if (createErr) {
        return console.log(createErr);
      }

      // $(".braintree-sheet__text").text(
      //   "Your card will not be charged until your free trial is completed"
      // );

      const submitButton = document.querySelector("#submit-button");

      instance.on("paymentMethodRequestable", function (event) {
        submitButton.removeAttribute("disabled");
      });

      instance.on("noPaymentMethodRequestable", function () {
        submitButton.setAttribute("disabled", true);
      });

      this.dropinInstance = instance;
    };

    try {
      const token = await this.service.fetchClientToken();

      const dropInOptions = {
        authorization: token,
        container: "#dropin-container",
        dataCollector: {
          kount: true,
        },
        card: {
          cardholderName: {
            required: true,
          },
        },
      };

      braintree.dropin.create(dropInOptions, createDropInHandler);
    } catch (err) {}
  }

  showModal(text, config = {}) {
    const { modal = false, className, callbacks, closeButton } = config;

    let src = `<div class="white-popup-block ${className}">${text}`;

    if (closeButton) {
      src += `<div class="white-popup-block__actions"><button id="close-popup">OK</button></div>`;
    }

    src += `</div>`;

    $.magnificPopup.open({
      items: {
        src,
      },
      callbacks,
      type: "inline",
      modal,
    });

    if (closeButton) {
      $("#close-popup-button").on("click", $.magnificPopup.close);
    }
  }

  showThankYou(html) {
    $(".thank-you").show();

    let defaultHtml = "";

    if (this.config.paymentServiceName === "shopify") {
      defaultHtml += `Thank you! You will be redirected to shopify page. Please finish you purchase there. `;
    } else {
      defaultHtml += `Thank you! Account was created for you. <br> You will be redirected now. <br>
      If not, then follow the instructions that we sent you on your email.`;
    }

    $("#thank-you-text").html(html || defaultHtml);
  }

  // openCheckout(userValues) {
  //   const { config } = this;
  //   const checkoutExpires = Date.now() + 30 * 60 * 1000; // +30min
  //   const appState = { config, checkoutExpires, userValues };
  //   localStorage.setItem("flc_lp_appState", JSON.stringify(appState));
  //   window.location.pathname += "checkout.html";
  // }
}

new App();

function populateFormWithTestValues() {
  // Testing Data
  const setValue = (name, val) => {
    $(".checkout-page__form").find(`[name='${name}']`).val(val);
  };
  setValue("email", "bradpittsubscription51@mailinator.com");
  setValue("parent_firstname", "Brad");
  setValue("parent_lastname", "Pitt");
  setValue("child_firstname", "Child");
  setValue("child_lastname", "Number1");
  // setValue("code", "T9hRfk8W5D");
  // setValue("country_code", "804");
  // setValue("locality", "Kharkiv");
  // setValue("street_address", "Test address");
  // setValue("plan_id", "grade-3");
}
